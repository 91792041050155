














































import { Component, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import PaymentStudents from "@/views/AutomaticPaymentsOverview/PaymentStudents.vue";
import { IStudent } from "@/interfaces/IStudent";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";
import { namespace } from "vuex-class";
import _ from "lodash";

const AppModule = namespace("app");
const PartialInvoiceModule = namespace("partial-invoice");

@Component({
  components: {
    PaymentHeader,
    PaymentStudents,
    SaveButton,
    AbortButton,
    HintMessage,
    FscCard,
  },
})
export default class PartialInvoice extends mixins(TabMixin) {
  public name = "PartialInvoice";

  @AppModule.Getter("getSelectedFlashPayStudents")
  protected getSelectedFlashPayStudents!: any;

  @AppModule.Action("setSelectedFlashPayStudents")
  protected setSelectedFlashPayStudents!: any;

  @PartialInvoiceModule.Action("generatePartialInvoices")
  protected generatePartialInvoices!: any;

  @PartialInvoiceModule.Getter("getSuccess")
  protected generatePartialInvoicesSuccess!: any;

  @PartialInvoiceModule.Getter("getIsLoading")
  protected generatePartialInvoicesIsLoading!: any;

  @PartialInvoiceModule.Mutation("SET_SUCCESS")
  protected SET_SUCCESS: any;

  protected selectedFlashPayStudents: Array<any> = [];

  protected onAbort(): void {
    this.closeCurrentTab();
  }

  protected onDeleteStudent(item: IStudent, index: number): void {
    this.getSelectedFlashPayStudents.splice(index, 1);
  }

  protected async onSubmit(): Promise<void> {
    await this.generatePartialInvoices(this.selectedFlashPayStudents);

    if (this.generatePartialInvoicesSuccess) {
      this.$toasted.success("Success!");
      this.closeCurrentTab();
    }
  }

  public mounted(): void {
    this.initSelectedFlashPayStudents();
  }

  protected initSelectedFlashPayStudents(): void {
    this.selectedFlashPayStudents = [];
    this.getSelectedFlashPayStudents.forEach((education: any) => {
      this.selectedFlashPayStudents.push({
        student: education.student,
        studentEducationId: education.studentEducationId,
        amountInEur: education.saldo.saldo < 0 ? Math.abs(education.saldo.saldo) : null,
      });
    });
    if (this.selectedFlashPayStudents.length === 0) {
      this.$nextTick(() => {
        this.closeCurrentTab();
      });
    }
  }

  @Watch("getSelectedFlashPayStudents")
  protected getSelectedClassicPayStudentsWatcher(): void {
    this.initSelectedFlashPayStudents();
  }

  public beforeDestroy(): void {
    this.$root.$emit("before-destroyed-component", {
      component: "FlashPay",
      success: this.generatePartialInvoicesSuccess,
    });
    this.SET_SUCCESS(false);
    this.setSelectedFlashPayStudents(null);
  }
}
